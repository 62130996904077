<template>
  <div id="saved-property-page">
    <div class="inner-acc">
      <div class="wrapper rowflex">
        <sidebar-account></sidebar-account>
        <article class="main">
          <div class="inner-savprop">
            <div class="search">
              <form @submit.prevent="searchSaved">
                <input
                  type="text"
                  v-model="form.search"
                  placeholder="Search from saved properties"
                />
                <button type="submit"></button>
              </form>
              <a
                href="javascript:"
                data-src="#popup-clearprop"
                class="cvr-bg-bf"
                data-fancybox
                >Clear All Saved Properties</a
              >
              <div
                id="popup-clearprop"
                class="popup-logout"
                style="display: none;"
              >
                <h2>Clear all properties?</h2>
                <a href="#" ref="cancelBtn" class="button btn-cancel">Cancel</a>
                <a
                  href="#"
                  class="button btn-confirm"
                  @click="deleteAllSavedProperty"
                  >Yes</a
                >
              </div>
              <!-- end of popup logout -->
            </div>
            <template v-if="listing.length > 0">
              <!-- end of search -->
              <div class="filter">
                <form>
                  <fieldset class="cvr-bg-af select">
                    <select>
                      <option>Transaction Type</option>
                      <option>Cash</option>
                      <option>Credit</option>
                    </select>
                  </fieldset>
                  <fieldset class="cvr-bg-af select">
                    <select>
                      <option>Property Type</option>
                      <option>House</option>
                      <option>Apartment</option>
                    </select>
                  </fieldset>
                  <fieldset class="cvr-bg-af select">
                    <select>
                      <option>City</option>
                      <option>Surabaya</option>
                      <option>Bandung</option>
                      <option>Jakarta</option>
                      <option>Jogja</option>
                    </select>
                  </fieldset>
                </form>
              </div>
              <!-- end of filter -->

              <div class="list">
                <template v-for="(property, index) in listing">
                  <div class="item" :key="index">
                    <figure>
                      <router-link
                        :to="`/property/${property.slug}`"
                        class="type"
                        :style="
                          `background-color: ${property.propertyType.class}`
                        "
                        v-if="property.propertyType"
                      >
                        {{ property.propertyType.name }}
                      </router-link>
                      <a
                        class="cvr-bg pointer bookmark marked"
                        @click="deleteBookmark(property.id)"
                      ></a>
                      <router-link :to="`/property/${property.slug}`">
                        <template v-if="property.mediaDescription === null">
                          <img src="/img/dummy/home/1.jpg" />
                        </template>
                        <template v-else>
                          <template
                            v-if="
                              checkType(property.mediaDescription[0]) == 'video'
                            "
                          >
                            <video style="width: 100%" controls autoplay>
                              <source
                                :src="property.mediaDescription[0]"
                                type="video/mp4"
                              />
                              Your browser does not support the video tag.
                            </video>
                          </template>
                          <template v-else>
                            <img
                              :src="property.mediaDescription[0]"
                              style="max-height: 250px"
                            />
                          </template>
                        </template>
                      </router-link>
                    </figure>
                    <div class="caption">
                      <h3>
                        <router-link :to="`/property/${property.slug}`">
                          {{ property.name }}
                        </router-link>
                      </h3>
                      <div class="location cvr-bg-bf">
                        <span>{{ property.region }}, {{ property.city }}</span>
                        <span v-if="property.furnishedType">
                          {{ property.furnishedType.name }}
                        </span>
                      </div>
                      <!-- end of location -->
                      <template
                        v-if="
                          property.transactionType.includes(1) ||
                            property.transactionType.includes(2)
                        "
                      >
                        <strong class="price">
                          <b>
                            Rp. {{ property.ratesMonthly | convertToRupiah }}
                          </b>
                          <small> / {{ $t("findProperty.Month") }}</small>
                          <br />
                          <b style="color: #093763" v-if="property.rates">
                            Rp. {{ property.rates | convertToRupiah }}
                          </b>
                        </strong>
                      </template>
                      <template v-else>
                        <strong class="price price-buy">
                          <em> Rp. {{ property.rates | convertToRupiah }} </em>
                        </strong>
                      </template>
                      <div class="amen">
                        <span
                          class="cvr-bg-bf ic-bed"
                          v-if="showBedAndBath(property.propertyTypeId)"
                        >
                          {{ property.numBedroom }}
                        </span>
                        <span
                          class="cvr-bg-bf ic-bath"
                          v-if="showBedAndBath(property.propertyTypeId)"
                        >
                          {{ property.numBathroom }}
                        </span>
                        <span class="cvr-bg-bf ic-land">
                          {{ property.landSize | convertToRupiah }} m<sup
                            >2</sup
                          >
                        </span>
                        <span class="cvr-bg-bf ic-build">
                          {{ property.buildingSize | convertToRupiah }} m
                          <sup>2</sup>
                        </span>
                      </div>
                      <!-- end of amen -->
                    </div>
                    <!-- end of caption -->
                  </div>
                  <!-- end of item -->
                </template>
              </div>
              <!-- end of list -->
              <pagination
                :total-pages="totalPages"
                :total="totalPages"
                :per-page="perPage"
                :current-page="currentPage"
                @pagechanged="onPageChange"
              ></pagination>
              <!-- end of pagination -->
            </template>
            <template v-else-if="listing.length == 0 && currentPage == 1">
              <div class="empty">
                <h2>You Have No Saved Properties</h2>
                <router-link to="/find-property" class="button">
                  Explore
                </router-link>
              </div>
            </template>
            <!-- end of empty -->
          </div>
          <!-- end of savprop -->
        </article>
      </div>
      <!-- end of wrapper -->
    </div>
    <!-- end of inner mort -->
  </div>
</template>

<script>
import SidebarAccount from "./SidebarAccount.vue";
import Pagination from "@/components/Pagination";

export default {
  name: "SavedProperties",
  metaInfo: {
    title: "Saved Properties"
  },
  components: {
    SidebarAccount,
    Pagination
  },
  data() {
    return {
      transactionType: [],
      propertyType: [],
      listing: [],
      currentPage: 1,
      perPage: 6,
      totalData: 1,
      totalPages: 1,
      form: {
        search: null,
        transactionType: null,
        propertyType: null,
        city: null
      }
    };
  },
  mounted() {
    this.savedProperty();
    this.getPropertyType();
    this.getTransactionType();
  },
  watch: {
    currentPage() {
      this.savedProperty();
    }
  },
  methods: {
    getPropertyType() {
      this.$axios
        .get(`/misc/property-types`, {
          params: {
            lang: this.$store.state.locale.lang
          }
        })
        .then(response => {
          this.propertyType = response.data.data;
        });
    },
    getTransactionType() {
      this.$axios
        .get(`/misc/transaction-types`, {
          params: {
            lang: this.$store.state.locale.lang
          }
        })
        .then(response => {
          this.transactionType = response.data.data;
        });
    },
    savedProperty() {
      this.$axios
        .get(`/properties/bookmarks`, {
          params: {
            page: this.currentPage,
            limit: this.perPage,
            search: this.form.search,
            transactionType: this.form.transaction,
            propertyType: this.form.propertyType,
            city: this.form.city
          }
        })
        .then(response => {
          this.listing = response.data.data.rows;
          this.totalData = response.data.data.count;
          this.totalPages = response.data.data.totalPages;
        });
    },
    deleteAllSavedProperty() {
      this.$axios
        .delete(`/properties/bookmarks`, {
          params: {
            page: this.currentPage,
            limit: this.perPage,
            search: this.form.search,
            transactionType: this.form.transaction,
            propertyType: this.form.propertyType,
            city: this.form.city
          }
        })
        .then(response => {
          this.$swal({
            icon: "success",
            text: response.data.message
          });
          this.$refs.cancelBtn.click();
          this.searchSaved();
        });
    },
    onPageChange(page) {
      this.currentPage = page;
    },
    searchSaved() {
      if (this.currentPage == 1) {
        this.savedProperty();
      } else {
        this.currentPage = 1;
      }
    },
    deleteBookmark(propertyId) {
      this.$axios
        .delete(`/properties/bookmarks/${propertyId}`)
        .then(response => {
          this.$swal({
            icon: "success",
            text: response.data.message
          });
          this.searchSaved();
        })
        .catch(error => {
          this.$swal({
            icon: "error",
            text: error.response.data.message
          });
        });
    }
  }
};
</script>
